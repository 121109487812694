$colors: ('red', var(--red), var(--beige)),
('red-lighter', var(--red-lighter), transparent),
('yellow', var(--yellow), var(--beige));
@each $name,
$color,
$color2 in $colors {
    .is-blinking-#{$name} {
        -webkit-animation: blinking-#{$name} 1s infinite;
        -moz-animation: blinking-#{$name} 1s infinite;
        -o-animation: blinking-#{$name} 1s infinite;
        animation: blinking-#{$name} 1s infinite;
    }
    @-moz-keyframes blinking-#{$name} {
        0%,
        49% {
            background-color: $color;
        }
        50%,
        100% {
            background-color: $color2;
        }
    }
    @-webkit-keyframes blinking-#{$name} {
        0%,
        49% {
            background-color: $color;
        }
        50%,
        100% {
            background-color: $color2;
        }
    }
    @keyframes blinking-#{$name} {
        0%,
        49% {
            background-color: $color;
        }
        50%,
        100% {
            background-color: $color2;
        }
    }
}