// Breakpoints
@mixin bp-4k {
    @media only screen and (min-width: 1441px) {
        @content;
    }
}

@mixin bp-nav {
    @media only screen and (min-width: 1100px) {
        @content;
    }
}

@mixin bp-mobilenav {
    @media only screen and (max-width: 1099px) {
        @content;
    }
}

@mixin bp-large {
    @media only screen and (min-width: 1025px) {
        @content;
    }
}

@mixin bp-medium {
    @media only screen and (min-width: 769px) {
        @content;
    }
}

@mixin bp-small {
    @media only screen and (min-width: 426px) {
        @content;
    }
}

@mixin bp-mobileLarge {
    @media only screen and (min-width: 376px) {
        @content;
    }
}

@mixin bp-mobileMedium {
    @media only screen and (min-width: 321px) {
        @content;
    }
}

@mixin bp-custom($bp) {
    @media only screen and (min-width: $bp) {
        @content;
    }
}

@mixin mobile-modal {
    @media only screen and (max-width: 768px) {
        @content;
    }
}