























































@import '../../assets/css/webp.scss';

.cover {
    display: flex;
    align-items: center;
    justify-content: center;
    @include bg-url('../../assets/img/cover.jpg', false, '../../assets/img/cover.webp', false, linear-gradient(to top, #{var(--bg-black-layer)}, #{var(--bg-black-layer)}));
    background-size: cover;
    background-position: center;

    padding: var(--nav-height) 0 calc(var(--nav-height)/2);

    color: var(--white);

    &.is-main-page {
        min-height: 85vh;
        padding-bottom: 0;
    }

    &.is-offline-page {
        width: 100vw !important;
        height: 100vh !important;
        box-sizing: border-box;
    }

    section {
        max-width: 720px;
        margin: 0 auto;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.6px;
    }
}
