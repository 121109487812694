












































































@import '../../assets/css/breakpoints.scss';

.order-buton-changer {
	position: relative;

	&__trigger {
		@include bp-mobilenav {
			display: none !important;
		}

		background-color: transparent;
		border-color: transparent;
		font-weight: bold;
		font-size: 16px;
		letter-spacing: 0.4px;
		text-transform: uppercase;
        color: var(--white);

		i {
			color: var(--white);
			margin-left: 5px;
		}
	}

	&__menu {
		display: none;

		position: absolute;
        width: 100%;
		bottom: 0;
		right: 0;
        transform: translateY(99%);
		align-items: stretch;
		justify-content: flex-end;

		@include bp-mobilenav {
			display: flex !important;
			flex-direction: column;
			position: relative;
			transform: translate(0%, 0);
		}

		&.is-open {
			display: flex;
		}

		> li {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 2.5px;
		}

        .button {
            white-space: nowrap;
        }
	}
}
