











































































































@import '../../assets/css/breakpoints.scss';

.nav {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	right: 0;

	height: var(--nav-height);

	> .app__container {
		overflow-x: visible;
	}

	&__container {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__site-name {
		display: inline-block;
		height: var(--nav-height);
		padding: 15px;
		box-sizing: border-box;
		// background-color: var(--nav-background);
		background-color: var(--mobile-background);

		img {
			height: 100%;
		}
	}

	&__menu-toggle {
		display: none;

		@include bp-mobilenav {
			display: block;
			position: relative;
			z-index: 120;
			margin: 25px;
		}
	}

	&__menu {
		i + span {
			display: none;
		}

		@include bp-mobilenav {
			position: absolute;
			visibility: hidden;
			pointer-events: none;

			&.is-mobile-menu-open {
				visibility: visible;
				pointer-events: auto;
				z-index: 110;
				width: 100vw;
				min-height: 100vh;
				top: 0;
				left: 0;
				background-color: var(--mobile-background);
				display: flex;
				flex-direction: column;
				align-items: stretch;
				justify-content: center;

				i {
					display: none;

					& + span {
						display: inline;
					}
				}

				.special-menu-button {
					margin-top: 10px;
				}
			}
		}

		> li {
			> a, > button.is-link {
				// color: var(--black);
				font-weight: bold;
				font-size: 16px;
				line-height: 46px;
				letter-spacing: 0.4px;
				text-transform: uppercase;
			}
			> .button {
				line-height: 30px;
			}
		}
	}
}
