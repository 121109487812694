






































@import '../../assets/css/breakpoints.scss';
.footer {
	background-color: var(--black);

	a {
		display: inline-block;
		font-size: 14px;
		line-height: 30px;
		padding: 10px;
		margin: 0 7.5px;
	}

	&__payments {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;

		margin: 20px 0;

		img {
			margin: 10px;
			height: 25px;
		}
	}

	&__copy {
		margin: 25px auto;

		color: var(--white);
		font-family: var(--font-header);
	}
}
