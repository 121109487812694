











































.offline {
	flex-direction: column;

	&__site-name {
		font-size: 75px;
		font-family: var(--font-header);
		margin-bottom: 50px;
	}
}
