



























.alarm-popup {
    padding: 30px;

    &__actions {
        position: absolute;
        top: 5px;
        right: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__close {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15px;
        height: 15px;
        margin: 2.5px;
        font-size: 12px;
        color: var(--black);
    }
}
