















.spinner {
    -webkit-animation:spin 2s ease-in-out infinite;
    -moz-animation:spin 2s ease-in-out infinite;
    animation:spin 2s ease-in-out infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
