@mixin bg-url($url, $url2x: false, $webp1x: false, $webp2x: false, $gradient: false) {
    @if $gradient {
        background-image: $gradient, url($url);
    }
    @else {
        background-image: url($url);
    }
    @if $webp1x {
        .webp & {
            @if $gradient {
                background-image: $gradient, url($webp1x);
            }
            @else {
                background-image: url($webp1x);
            }
        }
    }
    @if $url2x {
        @media screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
            @if $gradient {
                background-image: $gradient, url($url2x);
            }
            @else {
                background-image: url($url2x);
            }
            @if $webp2x {
                .webp & {
                    @if $gradient {
                        background-image: $gradient, url($webp2x);
                    }
                    @else {
                        background-image: url($webp2x);
                    }
                }
            }
        }
    }
}