// Mixins
@import './breakpoints.scss';
@import './animations.scss';
// Fonts
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&&family=Russo+One&display=swap');
:root {
    --font-lato: 'Lato', sans-serif;
    --font-russo: 'Russo One', sans-serif;
    --font: var(--font-lato);
    --font-header: var(--font-russo);
    // Colors
    --white: #fff;
    --beige: #f7f4f3;
    --black: #000;
    --red: #ee3041;
    --red-lighter: #f798a0;
    --yellow: #fce205;
    --green: #51a351;
    --green-darken: #387038;
    --green-lighter: #a8d1a8;
    --mobile-background: rgba(0, 0, 0, .95);
    --nav-background: rgba(255, 255, 255, .75);
    --black-shadow: rgba(0, 0, 0, 0.76);
    --menu-bg: #101010;
    --menu-box-bg: #2f2f2f;
    // Sizes
    --nav-height: 75px;
    // Shared vars
    --bg-black-layer: rgba(0, 0, 0, .5);
    --bg-black-layer-darker: rgba(0, 0, 0, .75);
    --border-list-header: rgba(0, 0, 0, .35);
    --border-list: rgba(0, 0, 0, .15);
}

* {
    outline: none !important;
}

html,
body {
    margin: 0;
    padding: 0;
    border: 0;
}

body {
    position: relative;
    font-size: 20px;
    min-height: 100vh;
    font-family: var(--font);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: var(--black);
    background-color: var(--white);
}

[data-tooltip] {
    position: relative;

    &:hover::after {
        position: absolute;
        z-index: 30;

        content: attr(data-tooltip);

        top: 100%;
        left: 50%;
        transform: translateX(-50%);

        margin-top: 10px;
        padding: 7.5px 15px;
        border-radius: 5px;

        background-color: #020617;
        color: #f1f5f9;
        font-size: 0.75rem;
        line-height: 1rem;
        white-space: nowrap;
    }
}
[data-tooltip-right] {
    &:hover::after {
        margin-top: 0px;
        // margin-left: 10px;
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
    }
}
[data-tooltip-left] {
    &:hover::after {
        margin-top: 0px;
        // margin-right: 10px;
        top: 50%;
        left: auto;
        right: 100%;
        transform: translateY(-50%);
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--font-header);
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 15px;
    @include bp-medium {
        margin-bottom: 30px;
    }
}

a,
button {
    &.is-link {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
    }
    &.button {
        display: inline-block;
        appearance: auto;
        padding: 7.5px 30px;
        font-family: var(--font);
        font-weight: 700;
        font-size: 20px;
        line-height: 46px;
        letter-spacing: 0.6px;
        text-transform: uppercase;
        background-color: transparent;
        border: 2px solid transparent;
        cursor: pointer;
        &.is-min-width {
            min-width: 200px;
        }
        &.is-color-white {
            color: var(--white);
        }
        &.is-small {
            padding: 5px 15px;
            font-size: 16px;
            line-height: 30px;
        }
        &.is-link {
            padding: 0;
        }
        &.is-red {
            color: var(--white);
            background-color: var(--red);
            border-color: var(--red);
            &:not(:disabled):hover {
                color: var(--white);
                box-shadow: 0px 0px 5px 0px var(--black-shadow);
            }
            &:disabled {
                border-color: var(--red-lighter);
                background-color: var(--red-lighter);
            }
        }
        &.is-black {
            color: var(--white);
            background-color: var(--black);
            border-color: var(--black);
            &:not(:disabled):hover {
                box-shadow: 0px 0px 5px 0px var(--black-shadow);
            }
            &:disabled {
                border-color: var(--black-shadow);
                background-color: var(--black-shadow);
            }
        }
        &.is-bordered {
            margin: 0 10px;
            padding: 10px 20px;
            border-color: var(--red);
            &:hover {
                color: var(--white);
                background-color: var(--red);
            }
        }
    }
}

a,
button.is-link {
    color: var(--white);
    text-decoration: none;
    &:hover {
        color: var(--red);
        text-decoration: none;
    }
}

.button+.button {
    margin-left: 5px;
}

.buttons-nav {
    display: flex;
    flex-direction: column;
    .button {
        margin: 7.5px 0;
    }
    @include bp-medium {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .button {
            margin: 0 7.5px;
            margin-bottom: 7.5px;
        }
    }
}

.special-menu-buttons {
    display: inline-flex;
    flex-direction: column;
    justify-items: stretch;
    min-width: 200px;
    margin: 15px auto;
    .button {
        margin: 5px 0;
        &+.button {
            margin-left: 0;
        }
    }
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
        display: inline-block;
        margin: 0 10px;
    }
}

form {
    .form__input,
    .form__select,
    .form__checkbox__input {
        &.has-errors {
            border: 1px solid var(--red);
            background-color: var(--red-lighter);
            transition: border .3s .3s, background-color .3s .3s;
        }
        &.has-success {
            border: 1px solid var(--green);
            background-color: var(--green-lighter);
            transition: border .3s .3s, background-color .3s .3s;
        }
    }
}

input.form__input,
textarea.form__input {
    display: block;
    width: 100%;
    padding: 10px;
    border: 1px solid var(--white);
    background-color: var(--white);
    color: var(--black);
    font-size: 20px;
    box-shadow: 0px 0px 1px 0px var(--black-shadow);
    box-sizing: border-box;
    transition: box-shadow 0.3s;
    resize: none;
    &.v-tooltip-open {
        margin-bottom: 55px;
    }
    &[type="file"] {
        font-size: 16px;
    }
    &:focus {
        box-shadow: 0px 0px 3px 0px var(--black-shadow);
    }
}

.is-crossed {
    text-decoration: line-through;
}

.error-text {
    color: var(--red);
}

.caseSensitive {
    text-transform: none;
}

#app {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    box-sizing: border-box;
    @include bp-mobilenav {
        &.is-mobile-menu-open {
            overflow: hidden;
            width: 100vw;
            height: 100vh;
        }
    }
}

.app__wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.app__container {
    width: 100vw;
    // max-width: 1100px;
    max-width: calc(100% - 10px);
    margin: 0 auto;
    padding: 0 10px;
    overflow-x: hidden;
    box-sizing: border-box;
    @include bp-4k {
        max-width: 1350px;
    }
}

.pageLoader {
    width: 100%;
    text-align: center;
    line-height: 100vh;
    font-family: var(--font-header);
    font-size: 35px;
}

.contentLoader {
    width: 100%;
    margin: 50px auto;
    text-align: center;
}

span.badge {
    display: inline-block;
    padding: 3px 6px;
    border-radius: 5px;
    color: var(--white);
    background-color: var(--red);
    vertical-align: middle;
    font-size: 10px;
    font-weight: bold;
}

.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.has-space-around {
        justify-content: space-around;
    }
    &.has-center {
        justify-content: center;
    }
}

.form-inline {
    margin: 25px 0;
    text-align: left;
    .form-group,
    .form-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        >div {
            flex-grow: 1;
            padding: 0 10px;
            margin-bottom: 25px;
            @include bp-medium {
                flex-shrink: 0;
                min-width: 50%;
                box-sizing: border-box;
            }
        }
    }
    .form-actions {
        margin-bottom: 0;
        .button {
            margin-top: 0;
        }
    }
}

.form-group__editable {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.vm--modal {
    @include mobile-modal {
        min-height: 100vh;
        top: 0 !important;
        >div {
            padding: 25px 15px 75px;
        }
    }
}

.points-modal {
    padding: 30px;
}

@import './tooltip.scss';
@import './wysiwyg.scss';