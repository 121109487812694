




































































@import '../../assets/css/breakpoints.scss';

.locale-changer {
	position: relative;

	&__trigger {
		@include bp-mobilenav {
			display: none !important;
		}

		background-color: transparent;
		border-color: transparent;
		font-weight: bold;
		font-size: 16px;
		letter-spacing: 0.4px;
		text-transform: uppercase;

		i {
			color: var(--white);
			margin-left: 5px;
		}
	}

	&__menu {
		display: none;

		position: absolute;
		bottom: 0;
		left: 50%;
		align-items: center;
		justify-content: center;
		transform: translate(-50%, 99%);
		// background-color: var(--black);

		@include bp-mobilenav {
			display: flex !important;
			position: relative;
			transform: translate(-50%, 0);
			height: 46px;
		}

		&.is-open {
			display: flex;
		}

		> li {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 2.5px;
		}

		&__button {
			background-color: transparent;
			border-color: transparent;
			padding: 2.5px;
			cursor: pointer;

			&.is-active, &:hover {
				background-color: var(--black);

				@include bp-mobilenav {
					background-color: var(--red);
				}
			}

			img {
				display: block;

				@include bp-mobilenav {
					box-shadow: 0px 0px 40px 5px black;
				}
			}
		}
	}
}
