












































img[src*="loading"] {
    width: auto;
    height: auto;
    object-fit: scale-down;
}
