









































































@import '../../assets/css/breakpoints.scss';

.phone-select {
	position: relative;

	&__trigger {
		@include bp-mobilenav {
			display: none !important;
		}

		color: var(--white);
		background-color: transparent;
		border-color: transparent;
		font-weight: bold;
		font-size: 16px;
		letter-spacing: 0.4px;
		text-transform: uppercase;

		i {
			color: var(--white);
			margin-left: 5px;
		}
	}

	&__menu {
		display: none;

		flex-direction: column;
		position: absolute;
		bottom: 0;
		right: 0;
		align-items: flex-end;
		justify-content: center;
		transform: translate(0, 99%);

		@include bp-mobilenav {
			display: flex !important;
			position: relative;
			align-items: center;
			transform: translate(0, 0);

			> li > a {
				margin-top: 10px;
			}
		}

		&.is-open {
			display: flex;
		}

		> li {
			margin: 2.5px;

			> a {
				white-space: nowrap;
			}
		}

	}
}
